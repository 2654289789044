export const endpoint = "https://binel.nesstion.com/requestsHTTP";
// export const endpoint = "http://localhost/requestsHTTP";


export const ROUTES = {
    // maps
    CREATEMAP:`${endpoint}/http_map.php/map`,
    // survey
    CREATESURVEY:`${endpoint}/http_survey.php/survey`,
    // interact
    CREATEINTERACT:`${endpoint}/http_interaction.php/interaction`
}