
import './App.css';
import MainChatBot from './Components/MainChatBot';

function App() {
  return (
    <div className="ChatBot">
      <MainChatBot/>
    </div>
  );
}

export default App;
