import React, { Component } from "react";

export default class LinkComponent extends Component {
  render() {
    return (
      <>
        <a href="https://drive.google.com/file/d/1qrdqTAZlIvqNEup5313hJH6ugg4FpB5z/view" rel="noreferrer" target="_blank">
          Bases de datos
        </a>
      </>
    );
  }
}
