import React, { Component } from "react";

export default class LinkComponent extends Component {
  render() {
    return (
      <>
        <a href="https://drive.google.com/file/d/1FLHuaWFR1R_AKNczTw0quqjX891CNFLk/view" rel="noreferrer" target="_blank">
          Gu&iacute;a repositorio institucional UPN
        </a>
        <br/>
        <br/>
        <a href="http://repositorio.pedagogica.edu.co" rel="noreferrer" target="_blank">
        Repositorio institucional UPN
        </a>
      </>
    );
  }
}
