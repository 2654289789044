import React, { Component } from 'react';

class FormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProgram: '',
    };
  }

    // Manejador del cambio de selección
    handleChange = (event) => {
      const selectedProgram = event.target.value;
      this.setState({ selectedProgram });
  
      // Verifica si triggerNextStep está disponible antes de usarlo
      if (this.props.triggerNextStep) {
        this.props.triggerNextStep({ value: selectedProgram }); // Pasar el valor seleccionado al chatbot
      } else {
        console.error("triggerNextStep no está disponible");
      }
    };

  render() {
    return (
      <select style={{width:325}} onChange={this.handleChange} value={this.state.selectedProgram}>
      <option value="">Seleccione una opci&oacute;n</option>
      <option value="1">Licenciatura en Educación Física</option>
      <option value="2">Licenciatura en Deporte</option>
      <option value="3">Licenciatura en Recreación y Turismo</option>
      <option value="4">Licenciatura en Biología</option>
      <option value="5">Licenciatura en Química</option>
      <option value="6">Licenciatura en Matemáticas</option>
      <option value="7">Licenciatura en Física</option>
      <option value="8">Licenciatura en Diseño Tecnológico</option>
      <option value="9">Licenciatura en Electrónica</option>
      <option value="10">Licenciatura en Tecnología</option>
      <option value="11">Licenciatura en Ciencias Naturales y Educación Ambiental</option>
      <option value="12">Licenciatura en Español e Inglés</option>
      <option value="13">Licenciatura en Español y Lenguas Extranjeras</option>
      <option value="14">Licenciatura en Ciencias Sociales</option>
      <option value="15">Licenciatura en Educación Básica Primaria</option>
      <option value="16">Licenciatura en Educación Especial</option>
      <option value="17">Licenciatura en Psicología y Pedagogía</option>
      <option value="18">Licenciatura en Educación Infantil</option>
      <option value="19">Licenciatura en Educación Comunitaria con énfasis en DDHH</option>
      <option value="20">Programa en Pedagogía</option>
      <option value="21">Licenciatura en Filosofía</option>
      <option value="22">Licenciatura en Educación Música</option>
      <option value="23">Licenciatura en Artes Escénicas</option>
      <option value="24">Licenciatura en Artes Visuales</option>
      <option value="25">Licenciatura en Música</option>
      <option value="26">Licenciatura en Educación Preescolar</option>
      <option value="27">Maestría en Docencia de la Química</option>
      <option value="28">Maestría en Tecnologías de la Información Aplicadas a la Educación</option>
      <option value="29">Maestría en Docencia de la Matemática</option>
      <option value="30">Maestría en Educación</option>
      <option value="31">Maestría en Desarrollo Educativo y Social (CINDE - UPN)</option>
      <option value="32">Maestría en Estudios Contemporáneos en Enseñanza de la Biología</option>
      <option value="33">Especialización en Enseñanza de la Biología</option>
      <option value="34">Maestría en Artes, Educación y Cultura</option>
      <option value="35">Maestría en Ciencias del Deporte y la Actividad Física</option>
      <option value="36">Maestría en Docencia de las Ciencias Naturales</option>
      <option value="37">Maestría en Estudios Sociales</option>
      <option value="38">Maestría en Enseñanza de Lenguas Extranjeras: énfasis Inglés/Francés</option>
      <option value="39">Maestría en Estudios en Infancias</option>
      <option value="40">Especialización en Gerencia Social de la Educación</option>
      <option value="41">Especialización en Edu. Especial Comun. Aumentativa y Alternativa (SP)</option>
      <option value="42">Especialización en Pedagogía (Semi-Presencial)</option>
      <option value="43">Especialización en Tecnologías de la Información Aplicadas a la Educación</option>
      <option value="44">Especialización en Docencia del Español como Lengua Propia</option>
      <option value="45">Especialización en Docencia de las Ciencias para el Nivel Básico</option>
      <option value="46">Especialización en Pedagogía del Entrenamiento Deportivo</option>
      <option value="47">Especialización en Pedagogía y Didáctica de la Educación Física</option>
      <option value="48">Especialización en Admón de la Educación Física, Recreación y Deporte</option>
      <option value="49">Especialización en Pedagogía (Distancia)</option>
      <option value="50">Especialización en Educación Matemática</option>
      <option value="51">Doctorado Interinstitucional en Educación</option>
    </select>
    );
  }
}

export default FormComponent;