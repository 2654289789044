import React, { Component } from 'react';
import ChatBot from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components';
import { CreateMap } from '../services/map.routes';
// import { CreateInteract } from '../services/interact.routes';
// import { CreateSurvey } from '../services/survey.routes';
import LinkComponent from './LinkComponent';
import PdfComponent from './PdfComponent';
import RepoComponent from './RepoComponent';
import FormComponent from './FormComponent';

const DiseñoChat = {
  background: '#f5f8fb',
  fontFamily: 'Arial, sans-serif',
  fontWeight: 600,
  headerBgColor: '#004991',
  headerFontColor: '#fff',
  headerFontSize: '15px',
  botBubbleColor: '#3366cc',
  botFontColor: '#fff',
  userBubbleColor: '#fff',
  userFontColor: '#4a4a4a',
  options:'display:flex; flex-direction:column; align-items:end;'
};

export default class MainChatBot extends Component {
   timer = null;

   state = {
    data: [],
    error: null,
    key: 'id', // Se define aquí directamente en el estado inicial
  };
  validarEmpty = (value) => {
     // Quitar espacios en blanco y verificar si está vacío
     if (value.trim() === '') {
      this.setState({ error: 'Este campo es obligatorio' });
      return 'Este campo es obligatorio'; // Mensaje de error que muestra en el chatbot
    }
    this.setState({ error: null });
    return true; // Permite continuar al siguiente paso si no está vacío

  }

  handleEnd = async ({ steps, renderedSteps }) => {
    // Mantiene el estado 'key'
    this.setState({ key: this.state.key });

    // IDs a excluir de los pasos
    const excludedIds = [
      "encuesta",
      "id",
      "id-1",
      "program",
      "program-1",
      "age",
      "range",
      "semester",
      "semester-1"
    ];

    // Filtra los pasos excluyendo IDs
    const filteredStepsArray = renderedSteps
      .filter(step => !excludedIds.some(excludedId => step.id.includes(excludedId)))
      .map(step => ({ ...step }));

      const mapData = filteredStepsArray.map(step => ({
        id: step.id,
        value: step.message || null, // Captura el valor del paso si existe
      }));

      const interactData = {
        cedula: steps['id-1']?.value ,
        semestre: steps['semester-1']?.value,
        fk_programa: steps['program-1']?.value,
        fk_edad: steps.range?.value ,
        fk_tipo_busqueda: steps['2-a-select-4']?.value || null ,
      };
      const encuestaData = {
        '1a':  steps['encuesta-1-1']?.value || null ,
        '1b':  steps['encuesta-2-1']?.value || null ,
        '1c':  steps['encuesta-3-1']?.value || null ,
        '1d':  steps['encuesta-4-1']?.value || null ,
        '1e':  steps['encuesta-5-1']?.value || null ,
        '1f':  steps['encuesta-6-1']?.value || null,
        '1g':  steps['encuesta-7-1']?.value || null ,
        '1h':  steps['encuesta-8-1']?.value || null ,

        '2a':  steps['encuesta-9-1']?.value || null ,
        '2b':  steps['encuesta-10-1']?.value || null ,
        '2c':  steps['encuesta-11-1']?.value || null ,
        '2d':  steps['encuesta-12-1']?.value || null ,
        '2e':  steps['encuesta-13-1']?.value || null ,

        '3a':  steps['encuesta-15-1']?.value || null ,
        '3b':  steps['encuesta-16-1']?.value || null ,
        '3c':  steps['encuesta-17-1']?.value || null ,
        '3d':  steps['encuesta-18-1']?.value || null ,

        '4a':  steps['encuesta-19-1']?.value || null ,
        '4b':  steps['encuesta-20-1']?.value || null ,
        '4c':  steps['encuesta-21-1']?.value || null ,
        '4d':  steps['encuesta-53-1']?.value || null ,
        '4e':  steps['encuesta-22-1']?.value || null ,
        '4f':  steps['encuesta-23-1']?.value || null ,
        '4g':  steps['encuesta-24-1']?.value || null ,

        '5a':  steps['encuesta-25-1']?.value || null ,
        '5b':  steps['encuesta-26-1']?.value || null ,
        '5c':  steps['encuesta-48-1']?.value || null ,
        '5d':  steps['encuesta-27-1']?.value || null ,
        '5e':  steps['encuesta-28-1']?.value || null ,
        '5f':  steps['encuesta-29-1']?.value || null ,
        '5g':  steps['encuesta-30-1']?.value || null ,
        '5h':  steps['encuesta-31-1']?.value || null ,
        '5i':  steps['encuesta-32-1']?.value || null ,
        '5j':  steps['encuesta-49-1']?.value || null ,

        '6a':  steps['encuesta-33-1']?.value || null ,
        '6b':  steps['encuesta-34-1']?.value || null ,
        '6c':  steps['encuesta-50-1']?.value || null ,
        '6d':  steps['encuesta-35-1']?.value || null ,
        '6e':  steps['encuesta-36-1']?.value || null ,
        '6f':  steps['encuesta-37-1']?.value || null ,
        '6g':  steps['encuesta-38-1']?.value || null ,

        '7a':  steps['encuesta-39-1']?.value || null ,
        '7b':  steps['encuesta-40-1']?.value || null ,
        '7c':  steps['encuesta-41-1']?.value || null ,
        '7d':  steps['encuesta-42-1']?.value || null ,
        '7e':  steps['encuesta-51-1']?.value || null ,

        '8a':  steps['encuesta-43-1']?.value || null ,
        '8b':  steps['encuesta-44-1']?.value || null ,
        '8c':  steps['encuesta-45-1']?.value || null ,
        '8d':  steps['encuesta-46-1']?.value || null ,
        '8e':  steps['encuesta-47-1']?.value || null ,
        '8f':  steps['encuesta-52-1']?.value || null ,

      };

      // Envío de datos a la API
      const sendSteps = async () => {
        try {
          await Promise.all([
            CreateMap({steps:mapData, data:[encuestaData], interactData}),
            // CreateSurvey({data:[encuestaData]} ),
            // CreateInteract(interactData)
          ]);
        } catch (error) {
          console.error("Error al enviar los datos:", error);
          this.setState({ error });
        }
      };
    
    await sendSteps();
    
    window.location.reload();
  };

  resetInactivityTimer = () => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.sendInactivityMessage();
    }, 300000); // 5 minuto de inactividad
  };

  sendInactivityMessage = () => {
    const userResponse = window.confirm('Parece que te has ausentado, ¿puedo ayudarte en algo más?');

    if (userResponse) {
      // Recargar la ventana para reiniciar toda la aplicación
      window.location.reload();
    }
  };

  handleUserActivity = () => {
    this.resetInactivityTimer();
  };

  componentDidMount() {
    this.resetInactivityTimer();
    window.addEventListener('mousemove', this.handleUserActivity);
    window.addEventListener('keypress', this.handleUserActivity);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    window.removeEventListener('mousemove', this.handleUserActivity);
    window.removeEventListener('keypress', this.handleUserActivity);
  }

  validarNumero = (value) => {
    
    if (isNaN(value) ) {
      return 'El campo debe ser numérico';
    }
    
    return true;
  };

  validarSemestre = (value) => {
    
    if (isNaN(value) || value > 10 || value <= 0) {
      return 'Ingrese un dato valido';
    }
    
    return true;
  };
  
  render() {    
    return (
        <ThemeProvider theme={DiseñoChat}>
          <ChatBot
            floating={true}
            key={this.state.key}
            handleEnd={this.handleEnd}
            headerTitle="BINEL"
            placeholder="Escribe un mensaje..."
            steps={[
              {
                id: 'id',
                message:'Ingresa tu identificación',
                trigger: 'id-1',
              },
              {
                id:'id-1',
                user:true,
                trigger:'program',
                validator: this.validarNumero
              },
              {
                id: 'program',
                message:'Programa (licenciatura)',
                trigger: 'program-1',
              },
              {
                id: 'program-1',
                component:<FormComponent/>,
                waitAction: true,
                trigger:"age"
              },              
              {
                id: 'age',
                message: "Edad",
                trigger: 'range',
              },
              {
                id: 'range',
                options:[
                  { value: 1, label: '16-18', trigger: 'semester' },
                  { value: 2, label: '18-21', trigger: 'semester' },
                  { value: 3, label: '+21', trigger: 'semester' },
                ],
              },
              {
                id: 'semester',
                message:'Semestre que cursa (numérico)',
                trigger: 'semester-1',
              },
              {
                id:'semester-1',
                user:true,
                trigger:'1',
                validator: this.validarSemestre,
              },
              {
                id: '1',
                message: 'Bienvenido a la Biblioteca Central UPN. ¿En qué puedo ayudarte hoy?',
                trigger: '2'
              },
              {
                id: '2',
                options:[
                  { value: 'Buscar información', label: 'Buscar información', trigger: '2-a' },
                  { value:'Información general', label:'Información general', trigger:'2-b' },
                  { value:'Formación usuarios', label:'Formación usuarios', trigger:'2-c'},
                ],
              },
              {
                id:'2-a',
                message:'Búsqueda de información académica básica. ¿Conoces el catalogo de libros de la Biblioteca Central UPN?',
                trigger:'2-a-select',
              },
                {
                  id:'2-a-select',
                  options:[
                    {value:'Sí lo conozco', label:'Sí lo conozco', trigger:'2-a-select-1'},
                    {value:'No lo conozco', label:'No lo conozco', trigger:'2-a-select-2'},
                  ],
                },
                {
                  id:'2-a-select-1',
                  message:'¿Sabías que el catálogo de libros de la BC, está compuesto por más de ciento dos mil (102000) volúmenes, entre libros, revistas, tesis y trabajos de grado, que están a disposición para ser solicitados y llevados a casa en préstamo?',
                  trigger:'2-a-select-2'
                },
                {
                  id:'2-a-select-2',
                  message:'Antes de comenzar tu búsqueda, piensa en lo que ya sabes sobre el tema. ¿Hay alguna palabra clave o autor que podrías usar?',
                  trigger:'2-a-select-3'
                },
                {
                  id:'2-a-select-3',
                  message:'¿Cual es tu objetivo principal para esta búsqueda? Definir tu objetivo puede ayudarte a encontrar información más relevante',
                  trigger:'2-a-select-4'
                },
                {
                  id:'2-a-select-4',
                  options:[
                    { value:1, label:'Catálogo de libros Biblioteca Central UPN', trigger:'2-a-select-4a'},
                      { value:2, label:'Bases de datos', trigger:'2-a-select-5a'},
                      { value:3, label:'Repositorio institucional UPN', trigger:'2-a-select-5b'},
                  ]
                },
                  {
                    id:'2-a-select-4a',
                    message:'Realizar búsquedas básicas de libros y realizar reserva para préstamo. Realizar búsquedas con palabras que describan tus necesidades de investigación. Puedes buscar por título, autor, tema, ISBN, ISSN, serie o asignatura.',
                    trigger:'2-a-select-4a-1'
                  },
                  {
                    id:'2-a-select-4b',
                    message:'¿Estás encontrando la información que necesitas? si no, prueba con diferentes palabras clave o consulta otras bases de datos',
                    trigger:'2-a-select-5'
                  },
                    {
                      id:'2-a-select-5',
                      options:[                        
                        { value:'Sí, gracias', label:'Sí, gracias', trigger:'2-a-select-5c' },
                        { value:'No, ayuda', label:'No, ayuda', trigger:'2-a-select-5d' },
                      ],
                    },
                    {
                      id:'2-a-select-5a',
                      message:'Buscar información avanzada en bases de datos académicas es esencial para realizar investigaciones de calidad',
                      trigger:'2-a-select-5a-1'
                    },
                      {
                        id:'2-a-select-5a-1',
                        message:'Recuerda que el proceso de búsqueda es una habilidad que se mejora con la práctica. Evalúa qué estrategias te están funcionando y cuáles necesitas ajustar',
                        trigger:'2-a-select-5a-2'
                      },
                        {
                          id:'2-a-select-5a-2',
                          message:'¿Conoces las bases de datos de suscripción que tiene a tu servicio la Biblioteca Central de la UPN?',
                          trigger:'2-a-select-5a-3'
                        },
                        {
                          id:'2-a-select-5a-3',
                          options:[
                            { value:'Sí las conozco', label:'Sí las conozco', trigger:'2-a-select-5a-3a'},
                            { value:'No las conozco', label:'No las conozco', trigger:'2-a-select-5a-3b'}
                          ]
                        },
                          {
                            id:'2-a-select-5a-3a',
                            message:'Pregúntate: ¿cómo puedo formular mi pregunta de investigación para obtener los resultados más relevantes en las bases de datos? Evalúa críticamente cada fuente de información. Pregúntate: ¿es esta una fuente confiable y relevante para mi investigación? Descarga la guía de uso para las bases de datos de suscripción de la Universidad Pedagógica Nacional Aquí…',
                            trigger:'2-a-select-5a-3z-pdf',
                          },
                          {
                            id:'2-a-select-5a-3b',
                            message:'Para ingresar a las bases de datos, debes tener en cuenta las siguientes credenciales: Nombre de usuario: Correo institucional completo y Contraseña: Su número de cédula. Sí presenta problemas de acceso, se puede comunicar al correo: epenac@upn.edu.co. Elige bases de datos académicas que sean relevantes para tu campo de estudio y realiza búsquedas. Podrás encontrar desde artículos que contienen adjuntos PDF o títulos que puedas referenciar en tus listados de referencia bibliográfica.',
                            trigger:'2-a-select-5a-3z-pdf'
                          },
                          {
                            id:'2-a-select-5a-4',
                            message:'¿Encontraste la información que buscabas?',
                            trigger:'2-a-select-5a-4a'
                          },
                            {
                              id:'2-a-select-5a-4a',
                              options:[
                                { value:'Sí gracias', label:'Sí gracias', trigger:'2-a-select-5d'},
                                { value:'No ayuda', label:'No ayuda', trigger:'2-a-select-5a-4b'},
                              ]
                            },
                            {
                              id:'2-a-select-5a-4b',
                              message:'Si no encuentras información realizando búsquedas básicas, puedes utilizar operadores Booleanos, aquí tienes un ejemplo:  AND: Para combinar términos y reducir el número de resultados (educación AND tecnología).  OR: Para incluir sinónimos o términos relacionados y ampliar los resultados (adolescentes OR jóvenes).  NOT: Para excluir términos que no son relevantes para tu búsqueda (salud NOT dieta)',
                              trigger:'2-a-select-5a-5'
                            },
                              {
                                id:'2-a-select-5a-5',
                                message:'Al evaluar los resultados de tu búsqueda, pregúntate: ¿cómo se relaciona esta información con lo que ya sé? ¿Qué nuevas preguntas surgen de estos datos? Monitorea tu progreso: ¿estás encontrando la información que esperabas? Si no es así, considera cambiar tus palabras clave o probar nuevas combinaciones.',
                                trigger:'2-a-select-5a-6'
                              },
                              {
                                id:'2-a-select-5a-6',
                                message:'Aplicar filtros de búsqueda: Usa filtros para refinar tus resultados, como fecha de publicación, tipo de documento (artículos, tesis, revisiones), idioma, etc. Algunos ejemplos de filtros pueden ser: años específicos (por ejemplo, publicaciones de los últimos 5 años), artículos revisados por pares, o tipos específicos de estudio, ejemplo, revisiones sistemáticas, estudios experimentales, etc…',
                                trigger:'2-a-select-5d'
                              },
                    {
                      id:'2-a-select-5b',
                      message:'El repositorio institucional es un recurso valioso para acceder a investigaciones y trabajos académicos específicos de la UPN. Una estrategia de búsqueda de información que puede resultar muy valiosa para aportar a tu investigación. Antes de iniciar tu búsqueda en el repositorio institucional UPN, reflexiona: ¿cuáles son los objetivos específicos de mi investigación y cómo puede este repositorio ayudarme a alcanzarlos? Al explorar el repositorio institucional UPN, mantén presente la pregunta: ¿cómo se conecta este trabajo con el contexto más amplio de mi área de estudio?',
                      trigger:'2-a-select-5b-1'
                    },
                      {
                        id:'2-a-select-5b-1',
                        message:'¿Conoces el Repositorio Institucional UPN?',
                        trigger:'2-a-select-5b-2'
                      },
                        {
                          id:'2-a-select-5b-2',
                          options:[
                            { value:'Sí lo conozco', label:'Sí lo conozco', trigger:'2-a-select-5b-2a'},
                            { value:'No lo conozco', label:'No lo conozco', trigger:'2-a-select-5b-2b'},
                          ]
                        },
                          {
                            id:'2-a-select-5b-2a',
                            message:'Te llevaré directamente al Repositorio Institucional UPN y adicionalmente te aportare una pequeña guía de uso',
                            trigger:'z-link'
                          },
                          {
                            id:'2-a-select-5b-2b',
                            message:'Conoce nuestro Repositorio Intitucional UPN aquí...',
                            trigger:'z-link'
                          },
                            {
                              id:'z-link-1',
                              message:'¿Encontraste la información que buscabas?',
                              trigger:'z-link-2'
                            },
                            {
                              id:'z-link-2',
                              options:[
                                { value:'Sí gracias', label:'Sí gracias', trigger:'2-a-select-5d'},
                                { value:'No ayuda', label:'No ayuda', trigger:'z-link-3'},
                              ]
                            },
                            {
                              id:'z-link-3',
                              message:'Haz una pausa y reflexiona después de cada búsqueda: ¿he encontrado información relevante y suficiente? Si no, ¿qué puedo cambiar para mejorar mis resultados? Al utilizar repositorios institucionales, piensa en el valor único que estos aportan: acceso a investigaciones y datos específicos de la institución que pueden no estar disponibles en otras fuentes.',
                              trigger:'2-a-select-5d'
                            },
                    {
                      id:'2-a-select-5c',
                      message:'Reflexiona  sobre los resultados que encontraste. ¿Responden a tu pregunta incial? ¿Qué tan útiles te resultaron? ¿Podríamos validar si la información que enconstraste es útil?',
                      trigger:'z-z-2',
                    },
                    {
                      id:'2-a-select-5d',
                      message:'Tus datos serán utilizados únicamente con fines educativos.',
                      trigger:'2-a-select-5d-1',
                    },
                    {
                      id:'2-a-select-5d-1',
                      message:'Realiza una nueva búsqueda, utiliza otro tipo de información que pueda ayudar a ubicar lo que necesitas. Pregúntale al Bibliotecólogo.',
                      trigger:'z-z-2'
                    },                      
                {
                  id:'2-a-select-2',
                  message:'¿Sabes como utilizar el catálogo de libros de la Biblioteca Central UPN? Es muy fácil, solo debes ingresar a www.biblitecaupn.edu.co ¿Te puedo llevar al catálogo?',
                  trigger:'2-a-select-2a'
                },
                  {
                    id:'2-a-select-2a',
                    options:[
                      { value:'Sí, por favor', label:'Sí, por favor', trigger:'2-a-select-4' },
                      { value:'Finalizar', label:'Finalizar', trigger:'encuesta' }
                    ],
                  },
                    {
                      id:'2-a-select-4a-1',
                      component: <LinkComponent />,
                      asMessage: true,
                      trigger: "2-a-select-4b"
                    },
                    /* ------------------------------------------------------------------------------------------------------------- */
                {
                  id:'2-b',
                  options:[
                    { value:'Biblioteca Central', label:'Biblioteca Central', trigger:'2-b-1'},
                    { value:'Valmaría', label:'Valmaría', trigger:'2-b-2'},
                    { value:'IPN', label:'IPN', trigger:'2-b-3'},
                  ],
                },
                  {
                    id:'2-b-1',
                    message:'Subdirección de biblioteca documentación y Recursos bibliográficos. Ubicación: Calle 72 N° 11-86, Edificio A, Primer piso.',
                    trigger:'z-z-2'
                  },                  
                  {
                    id:'2-b-2',
                    message:'Biblioteca especializada en Educación Física Colección Especializada en Educación Física, deportes y Recreación. Ubicación: Calle 183 N° 54D esquina - Sede Valmaría',
                    trigger:'z-z-2'
                  },
                  {
                    id:'2-b-3',
                    message:'Biblioteca Escolar del Instituto Pedagógico Nacional. Ubicación: Av. Calle 127 N° 11-20',
                    trigger:'z-z-2'
                  },
                  /* ---------------------------------------------------------------------------------------------------------------------------   */
                {
                  id:'2-c',
                  message:'Servicios de formación de usuarios',
                  trigger: '2-c-select'
                },
                  {
                    id:'2-c-select',
                    options:[
                      { value:'Temáticas', label:'Temáticas', trigger:'2-c-select-1'},
                      { value:'Sesiones formación', label:'Sesiones formación', trigger:'2-c-select-2'}
                    ],                    
                  },
                    {
                      id:'2-c-select-1',
                      message:'Temáticas que se ofrecen: 1° Acceso a la información. 2° Estrategias de búsqueda. 3° Citar y referenciar en norma APA. 4° Derechos de autor y turnitin.',
                      trigger:'z-z-2'
                    },
                    {
                      id:'2-c-select-2',
                      message:'Se ofrecen capacitaciones grupales, con un máximo de 10 personas, con una duración de 2 horas. Asesorías de menos de 4 personas, con una duración de 40 minutos.',
                      trigger:'z-z-2'
                    },
                {
                  id:'2-a-select-5a-3z-pdf',
                  component:<PdfComponent/>,
                  asMessage:true,
                  trigger:'2-a-select-5a-4'
                },
                {
                  id:'z-link',
                  component:<RepoComponent/>,
                  asMessage:true,
                  trigger:'z-link-1'
                },
                {
                  id:'encuesta',
                  message:'Por favor, califica cada afirmación de acuerdo con lo que mejor describa tus hábitos o actitudes habituales al buscar información académica, utilizando la siguiente escala: 1. Completamente en desacuerdo, 2. En desacuerdo, 3. Ni en desacuerdo ni de acuerdo, 4. De acuerdo y 5. Completamente de acuerdo.',
                  trigger:'encuesta-1'
                },
                {
                  id:'encuesta-1',
                  message:'¿Qué sabes sobre tus capacidades de búsqueda?',
                  trigger:'encuesta-pre-1-1'
                },
                {
                  id:'encuesta-pre-1-1',
                  message:'Entiendo mis fortalezas y debilidades al buscar información académica',
                  trigger:'encuesta-1-1'
                },
                {
                  id:'encuesta-1-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-2'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-2'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-2'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-2'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-2'},
                  ]
                },
                {
                  id:'encuesta-2',
                  message:'Sé qué tipo de fuentes son las más relevantes para mi tema',
                  trigger:'encuesta-2-1'
                },
                {
                  id:'encuesta-2-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-3'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-3'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-3'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-3'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-3'},
                  ]
                },
                {
                  id:'encuesta-3',
                  message:'Soy bueno organizando información relevante',
                  trigger:'encuesta-3-1'
                },
                {
                  id:'encuesta-3-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-4'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-4'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-4'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-4'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-4'},
                  ]
                },
                {
                  id:'encuesta-4',
                  message:'Sé cómo identificar lo que el profesor espera que investigue',
                  trigger:'encuesta-4-1'
                },
                {
                  id:'encuesta-4-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-5'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-5'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-5'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-5'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-5'},
                  ]
                },
                {
                  id:'encuesta-5',
                  message:'Puedo recordar dónde encontré información útil previamente',
                  trigger:'encuesta-5-1'
                },
                {
                  id:'encuesta-5-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-6'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-6'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-6'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-6'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-6'},
                  ]
                },
                {
                  id:'encuesta-6',
                  message:'Tengo control sobre qué tan bien gestiono mis búsquedas',
                  trigger:'encuesta-6-1'
                },
                {
                  id:'encuesta-6-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-7'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-7'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-7'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-7'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-7'},
                  ]
                },
                {
                  id:'encuesta-7',
                  message:'Soy buen juez de qué tan bien comprendo la información encontrada',
                  trigger:'encuesta-7-1'
                },
                {
                  id:'encuesta-7-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-8'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-8'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-8'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-8'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-8'},
                  ]
                },
                {
                  id:'encuesta-8',
                  message:'Busco con más interés cuando el tema es relevante para mí',
                  trigger:'encuesta-8-1'
                },
                {
                  id:'encuesta-8-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-9'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-9'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-9'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-9'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-9'},
                  ]
                },
                {
                  id:'encuesta-9',
                  message:'¿Cómo realizas las búsquedas?',
                  trigger:'encuesta-pre-9-1'
                },
                {
                  id:'encuesta-pre-9-1',
                  message:'Trato de usar estrategias que me han funcionado en búsquedas anteriores',
                  trigger:'encuesta-9-1'
                },
                {
                  id:'encuesta-9-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-10'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-10'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-10'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-10'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-10'},
                  ]
                },
                {
                  id:'encuesta-10',
                  message:'Tengo un propósito claro para cada estrategia de búsqueda que uso',
                  trigger:'encuesta-10-1'
                },
                {
                  id:'encuesta-10-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-11'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-11'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-11'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-11'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-11'},
                  ]
                },
                {
                  id:'encuesta-11',
                  message:'Soy consciente de las estrategias que uso al buscar información',
                  trigger:'encuesta-11-1'
                },
                {
                  id:'encuesta-11-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-12'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-12'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-12'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-12'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-12'},
                  ]
                },
                {
                  id:'encuesta-12',
                  message:'Uso automáticamente estrategias que sé que son útiles',
                  trigger:'encuesta-12-1'
                },
                {
                  id:'encuesta-12-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-13'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-13'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-13'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-13'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-13'},
                  ]
                },
                {
                  id:'encuesta-13',
                  message:'Sé cuándo cada estrategia de búsqueda será más efectiva',
                  trigger:'encuesta-13-1'
                },
                {
                  id:'encuesta-13-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-15'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-15'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-15'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-15'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-15'},
                  ]
                },
                {
                  id:'encuesta-15',
                  message:'¿Cuándo y por qué usas estrategias específicas?',
                  trigger:'encuesta-pre-15-1'
                },
                {
                  id:'encuesta-pre-15-1',
                  message:'Aprendo mejor cuando tengo antecedentes sobre el tema',
                  trigger:'encuesta-15-1'
                },
                {
                  id:'encuesta-15-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-16'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-16'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-16'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-16'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-16'},
                  ]
                },
                {
                  id:'encuesta-16',
                  message:'Uso diferentes estrategias de búsqueda según la base de datos o recurso',
                  trigger:'encuesta-16-1'
                },
                {
                  id:'encuesta-16-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-17'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-17'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-17'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-17'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-17'},
                  ]
                },
                {
                  id:'encuesta-17',
                  message:'Puedo motivarme a buscar información incluso cuando no me interesa',
                  trigger:'encuesta-17-1'
                },
                {
                  id:'encuesta-17-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-18'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-18'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-18'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-18'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-18'},
                  ]
                },
                {
                  id:'encuesta-18',
                  message:'Uso mis fortalezas para compensar dificultades en la búsqueda',
                  trigger:'encuesta-18-1'
                },
                {
                  id:'encuesta-18-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-19'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-19'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-19'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-19'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-19'},
                  ]
                },
                {
                  id:'encuesta-19',
                  message:'Organización antes de buscar información',
                  trigger:'encuesta-pre-19-1'
                },
                {
                  id:'encuesta-pre-19-1',
                  message:'Planifico cómo buscar información antes de comenzar',
                  trigger:'encuesta-19-1'
                },
                {
                  id:'encuesta-19-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-20'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-20'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-20'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-20'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-20'},
                  ]
                },
                {
                  id:'encuesta-20',
                  message:'Me pregunto qué necesito encontrar antes de iniciar la búsqueda',
                  trigger:'encuesta-20-1'
                },
                {
                  id:'encuesta-20-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-21'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-21'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-21'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-21'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-21'},
                  ]
                },{
                  id:'encuesta-21',
                  message:'Establezco metas específicas para mi búsqueda de información',
                  trigger:'encuesta-21-1'
                },
                {
                  id:'encuesta-21-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-53'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-53'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-53'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-53'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-53'},
                  ]
                },
                {
                  id:'encuesta-53',
                  message:'Me hago preguntas sobre el material antes de comenzar',
                  trigger:'encuesta-53-1'
                },
                {
                  id:'encuesta-53-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-22'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-22'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-22'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-22'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-22'},
                  ]
                },
                {
                  id:'encuesta-22',
                  message:'Pienso en varias maneras de buscar información y elijo la mejor',
                  trigger:'encuesta-22-1'
                },
                {
                  id:'encuesta-22-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-23'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-23'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-23'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-23'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-23'},
                  ]
                },
                {
                  id:'encuesta-23',
                  message:'Leo cuidadosamente las instrucciones de búsqueda en bases de datos',
                  trigger:'encuesta-23-1'
                },
                {
                  id:'encuesta-23-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-24'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-24'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-24'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-24'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-24'},
                  ]
                },
                {
                  id:'encuesta-24',
                  message:'Organizo mi tiempo para completar la búsqueda con éxito',
                  trigger:'encuesta-24-1'
                },
                {
                  id:'encuesta-24-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-25'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-25'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-25'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-25'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-25'},
                  ]
                },
                {
                  id:'encuesta-25',
                  message:'Procesar y organizar datos',
                  trigger:'encuesta-pre-25-1'
                },
                {
                  id:'encuesta-pre-25-1',
                  message:'Reduzco la velocidad al analizar fuentes importantes',
                  trigger:'encuesta-25-1'
                },
                {
                  id:'encuesta-25-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-26'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-26'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-26'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-26'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-26'},
                  ]
                },
                {
                  id:'encuesta-26',
                  message:'Me concentro en la información más relevante en las fuentes académicas',
                  trigger:'encuesta-26-1'
                },
                {
                  id:'encuesta-26-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-48'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-48'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-48'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-48'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-48'},
                  ]
                },
                {
                  id:'encuesta-48',
                  message:'Me enfoco en el significado y la importancia de nueva información',
                  trigger:'encuesta-48-1'
                },
                {
                  id:'encuesta-48-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-27'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-27'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-27'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-27'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-27'},
                  ]
                },
                {
                  id:'encuesta-27',
                  message:'Creo ejemplos o resúmenes para entender mejor lo que encuentro',
                  trigger:'encuesta-27-1'
                },
                {
                  id:'encuesta-27-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-28'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-28'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-28'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-28'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-28'},
                  ]
                },
                {
                  id:'encuesta-28',
                  message:'Uso gráficos o diagramas para organizar los conceptos clave',
                  trigger:'encuesta-28-1'
                },
                {
                  id:'encuesta-28-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-29'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-29'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-29'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-29'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-29'},
                  ]
                },
                {
                  id:'encuesta-29',
                  message:'Reformulo la información en mis propias palabras para comprenderla mejor',
                  trigger:'encuesta-29-1'
                },
                {
                  id:'encuesta-29-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-30'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-30'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-30'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-30'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-30'},
                  ]
                },
                {
                  id:'encuesta-30',
                  message:'Uso la estructura del texto o base de datos para encontrar información rápidamente',
                  trigger:'encuesta-30-1'
                },
                {
                  id:'encuesta-30-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-31'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-31'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-31'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-31'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-31'},
                  ]
                },
                {
                  id:'encuesta-31',
                  message:'Me pregunto si lo que encuentro está relacionado con lo que ya sé',
                  trigger:'encuesta-31-1'
                },
                {
                  id:'encuesta-31-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-32'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-32'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-32'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-32'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-32'},
                  ]
                },
                {
                  id:'encuesta-32',
                  message:'Divido mi búsqueda en pasos más pequeños y manejables',
                  trigger:'encuesta-32-1'
                },
                {
                  id:'encuesta-32-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-49'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-49'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-49'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-49'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-49'},
                  ]
                },
                {
                  id:'encuesta-49',
                  message:'Me enfoco en el significado general más que en los detalles',
                  trigger:'encuesta-49-1'
                },
                {
                  id:'encuesta-49-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-33'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-33'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-33'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-33'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-33'},
                  ]
                },
                {
                  id:'encuesta-33',
                  message:'Supervisión de la búsqueda',
                  trigger:'encuesta-pre-33-1'
                },
                {
                  id:'encuesta-pre-33-1',
                  message:'Me pregunto periódicamente si estoy alcanzando los objetivos de búsqueda',
                  trigger:'encuesta-33-1'
                },
                {
                  id:'encuesta-33-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-34'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-34'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-34'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-34'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-34'},
                  ]
                },
                {
                  id:'encuesta-34',
                  message:'Considero varias alternativas antes de decidir sobre una fuente',
                  trigger:'encuesta-34-1'
                },
                {
                  id:'encuesta-34-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-50'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-50'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-50'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-50'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-50'},
                  ]
                },
                {
                  id:'encuesta-50',
                  message:'Me pregunto si he considerado todas las opciones al resolver un problema',
                  trigger:'encuesta-50-1'
                },
                {
                  id:'encuesta-50-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-35'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-35'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-35'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-35'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-35'},
                  ]
                },
                {
                  id:'encuesta-35',
                  message:'Reviso regularmente mi progreso en la búsqueda',
                  trigger:'encuesta-35-1'
                },
                {
                  id:'encuesta-35-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-36'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-36'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-36'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-36'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-36'},
                  ]
                },
                {
                  id:'encuesta-36',
                  message:'Analizo la utilidad de las estrategias mientras busco',
                  trigger:'encuesta-36-1'
                },
                {
                  id:'encuesta-36-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-37'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-37'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-37'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-37'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-37'},
                  ]
                },
                {
                  id:'encuesta-37',
                  message:'Pauso para verificar si entiendo la información que encuentro',
                  trigger:'encuesta-37-1'
                },
                {
                  id:'encuesta-37-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-38'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-38'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-38'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-38'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-38'},
                  ]
                },
                {
                  id:'encuesta-38',
                  message:'Me hago preguntas sobre cómo estoy manejando mi búsqueda',
                  trigger:'encuesta-38-1'
                },
                {
                  id:'encuesta-38-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-39'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-39'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-39'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-39'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-39'},
                  ]
                },
                {
                  id:'encuesta-39',
                  message:'Corrección de problemas en la búsqueda',
                  trigger:'encuesta-pre-39-1'
                },
                {
                  id:'encuesta-pre-39-1',
                  message:'Pido ayuda cuando no entiendo cómo acceder a ciertas fuentes',
                  trigger:'encuesta-39-1'
                },
                {
                  id:'encuesta-39-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-40'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-40'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-40'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-40'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-40'},
                  ]
                },
                {
                  id:'encuesta-40',
                  message:'Cambio de estrategia si no estoy obteniendo buenos resultados',
                  trigger:'encuesta-40-1'
                },
                {
                  id:'encuesta-40-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-41'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-41'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-41'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-41'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-41'},
                  ]
                },
                {
                  id:'encuesta-41',
                  message:'Reevaluó mis suposiciones cuando la búsqueda no avanza',
                  trigger:'encuesta-41-1'
                },
                {
                  id:'encuesta-41-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-42'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-42'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-42'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-42'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-42'},
                  ]
                },
                {
                  id:'encuesta-42',
                  message:'Vuelvo a revisar fuentes o términos si la información no es clara',
                  trigger:'encuesta-42-1'
                },
                {
                  id:'encuesta-42-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-51'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-51'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-51'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-51'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-51'},
                  ]
                },
                {
                  id:'encuesta-51',
                  message:'Me detengo y vuelvo a leer cuando me confundo',
                  trigger:'encuesta-51-1'
                },
                {
                  id:'encuesta-51-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-43'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-43'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-43'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-43'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-43'},
                  ]
                },
                {
                  id:'encuesta-43',
                  message:'Reflexión después de la búsqueda',
                  trigger:'encuesta-pre-43-1'
                },
                {
                  id:'encuesta-pre-43-1',
                  message:'Sé qué tan bien cumplí mis metas de búsqueda al finalizar',
                  trigger:'encuesta-43-1'
                },
                {
                  id:'encuesta-43-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-44'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-44'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-44'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-44'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-44'},
                  ]
                },
                {
                  id:'encuesta-44',
                  message:'Me pregunto si hubo una manera más eficiente de buscar',
                  trigger:'encuesta-44-1'
                },
                {
                  id:'encuesta-44-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-45'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-45'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-45'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-45'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-45'},
                  ]
                },
                {
                  id:'encuesta-45',
                  message:'Resumo lo que aprendí de las fuentes revisadas',
                  trigger:'encuesta-45-1'
                },
                {
                  id:'encuesta-45-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-46'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-46'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-46'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-46'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-46'},
                  ]
                },
                {
                  id:'encuesta-46',
                  message:'Reflexiono sobre cómo mejorar mis estrategias en futuras búsquedas',
                  trigger:'encuesta-46-1'
                },
                {
                  id:'encuesta-46-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-47'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-47'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-47'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-47'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-47'},
                  ]
                },
                {
                  id:'encuesta-47',
                  message:'Me pregunto si encontré toda la información que necesitaba',
                  trigger:'encuesta-47-1'
                },
                {
                  id:'encuesta-47-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'encuesta-52'},
                    {value:'En desacuerdo', label:'2', trigger:'encuesta-52'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'encuesta-52'},
                    {value:'De acuerdo', label:'4', trigger:'encuesta-52'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'encuesta-52'},
                  ]
                },
                {
                  id:'encuesta-52',
                  message:'Me pregunto si aprendí todo lo que pude después de terminar una tarea',
                  trigger:'encuesta-52-1'
                },
                {
                  id:'encuesta-52-1',
                  options:[
                    {value:'Completamente en desacuerdo', label:'1', trigger:'z-z-3'},
                    {value:'En desacuerdo', label:'2', trigger:'z-z-3'},
                    {value:'Ni en desacuerdo ni de acuerdo', label:'3', trigger:'z-z-3'},
                    {value:'De acuerdo', label:'4', trigger:'z-z-3'},
                    {value:'Completamente de acuerdo', label:'5', trigger:'z-z-3'},
                  ]
                },
                {
                  id:'z-z-2',
                  options:[
                    { value:'Finalizar', label:'Finalizar', trigger:'encuesta' },
                  ],
                },
              {
                id: 'z-z-3',
                message: "Gracias por utilizar nuestros servicios. Nos vemos en otra ocasión",
                trigger: 'z-z-4',
              },
              {
                id:'z-z-4',
                message:'Hasta luego',
                end:true,
              },
            ]}
          />
        </ThemeProvider>
    );
  }
}
