import React, { Component } from "react";

export default class LinkComponent extends Component {
  render() {
    return (
      <>
        <a href="http://catalogo.upn.edu.co/" rel="noreferrer" target="_blank">
          Cat&aacute;logo biblioteca
        </a>
      </>
    );
  }
}
