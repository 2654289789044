import axios from "axios";
import { ROUTES } from "./api.routes";


export const CreateMap = async (data) => {
    try {
      const response = await axios.post(ROUTES.CREATEMAP, data);
      return {
        error: false ,
        data: response.data
      };
    } catch (error) {
  
      return {
        error: true ,
        data: error.response
      }
    }
};